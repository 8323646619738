import { Component, OnInit } from '@angular/core';
import { alert } from 'devextreme/ui/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { AppConfig } from '../app.config';
import { ActivatedRoute, Router} from '@angular/router';
import { environment } from './../../environments/environment';
import validationEngine from 'devextreme/ui/validation_engine';
import { GlobaleDatenService } from '../services/globaledaten.serice';
import { TranslateService } from '@ngx-translate/core';
import { AppRouteChangeService } from '../services/app-route-change.service';
import { enuKunde } from '../app-config.model';

export enum enuVergessen {
  Benutzername = 1,
  Passwort  = 2,
}
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  kennwortPattern: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\s\d])(?=.*[a-zA-Z]).{8,}$/;
  daten: any;
  successMessage: boolean=false;
  resetButtonOptions: any;
  cancelButtonOptions: any;
  userfieldOptions: any;
  benutzerPattern: string = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ.-@_';

  vergessentyp: enuVergessen = enuVergessen.Passwort; 
  isloggedIn: boolean;
  enuKunde = enuKunde;
  constructor(
    private fb: UntypedFormBuilder,
    public authService: AuthService,
    public gdService: GlobaleDatenService,
    // private deviceService: DeviceDetectorService,
    private router: Router,
    public translate: TranslateService,
    public appRouteChangeService: AppRouteChangeService,
    private route: ActivatedRoute,
  ) {
    this.detectDevice();
    // this.einstellungen.SetDefault();

    this.sProduktivText = `Sie melden sich am –  Produktivsystem – der Bergwacht Bayern an.
    Sämtliche Dateneingaben und –änderungen in diesem System
    führen somit zu einer Änderung der Produktivdaten.'`;
    this.sTestText = `Sie melden sich am –  Testsystem – der Bergwacht Bayern an.
    Sämtliche Dateneingaben und –änderungen in diesem System
    werden nur temporär gespeichert!.`;

    if (AppConfig.settings.testversion) {
      // this.backgroundImage = './../../assets/img/bergkette2_testsystem.png';
      this.bwb_system = 'bwbsystem_test';
    } else {
      // this.backgroundImage = './../../assets/img/bergkette2.png';
      this.bwb_system = 'bwbsystem_prod';
    }
    this.isloggedIn = this.authService.loggedIn();
    this.userfieldOptions = {
      onKeyDown: (e) => {
        return this.gdService.onKeyDown(e);
      },
      onFocusIn: () => {
        // this.captchaVisible = true;
      }
    };

    this.wechsel = this.wechsel.bind(this);
  }

  get backgroundImageUrl() {
    // if (this.backgroundImage) {
    //   return `url("${this.backgroundImage}")`;
    // }

    return null;
  }
  myForm: UntypedFormGroup;
  myForm2: UntypedFormGroup;
  public benutzername: AbstractControl;
  public email: AbstractControl;
  // public password: AbstractControl;
  // public password2: AbstractControl;

  public loadingVisible = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  access_token: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  refresh_token: string;
  public myAppConfig = AppConfig;

  public datenschutzVisible = false;
  public dsgvo = false;
  public deviceInfo: any;
  public iexplorer: boolean;

  public bergwachtwechselVisible = false;
  public infoProduktivVisible = false;

  // passwordMode: string;
  // passwordButton: any;

  public sTitel: string;
  public sProduktivTitel = 'Achtung Produktivbetrieb!';
  public sTestTitel = 'Achtung Testsystem!';

  public sText: string;
  public sProduktivText = 'Achtung Produktivbetrieb!';
  public sTestText = 'Achtung Testsystem!';

  // backgroundImage = './../../assets/img/bergkette2.png';
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  bwb_system = 'bwbsystem_test';


    detectDevice() {
      // this.deviceInfo = this.deviceService.getDeviceInfo();
      // const isMobile = this.deviceService.isMobile();
      // const isTablet = this.deviceService.isTablet();
      // const isDesktopDevice = this.deviceService.isDesktop();
      // console.log(this.deviceInfo);
      // console.log(isMobile);
      // console.log(isTablet);
      // console.log(isDesktopDevice);
      // console.log(this.deviceInfo.browser);
      // if (this.deviceInfo.browser === 'ie' || this.deviceInfo.browser === 'IE') { this.iexplorer = true; }

    }

    reloadData(){

      this.appRouteChangeService.reloadData();

      this.cancelButtonOptions = {
        icon: 'clear',
        text: this.translate.instant('RESETPASSWORD.ABBRECHEN'),
        type: 'button',
        useSubmitBehavior: false,
        onClick: (e) => {
          this.onCancel();
        }
      };

    }

  removeFilterValue(storageKey) {
    // console.log('removeFilterValue', storageKey);
    const storageItem = JSON.parse(localStorage.getItem(storageKey));
    if (storageItem != null) {
      for (const item of storageItem.columns) {
        if (item.hasOwnProperty('filterValues')) {
          delete item.filterValues;
        } else if (item.hasOwnProperty('filterValue')) {
          delete item.filterValue;
        }
      }
      if (storageItem.filterValue) {
        delete storageItem.filterValue;
      }
      if (storageItem.pageSize) {
        delete storageItem.pageSize;
      }
      if (storageItem.pageIndex) {
        // console.log('removeFilterValue pageIndex', storageItem.pageIndex);
        storageItem.pageIndex = 0;
      }
      localStorage.setItem(storageKey, JSON.stringify(storageItem));
    }
  }

  public onReset() {
    // this.router.navigate(['/passwordreset']);
    // console.log('onSignin ');
    this.loadingVisible = true;
    this.authService.passwordreset( { benutzername: this.myForm['benutzername']})
    .then((res) => {
      // console.log('onSignin 1');
      this.loadingVisible = false;
      alert( this.translate.instant('RESETPASSWORD.BESTAETIGUNGSEMAIL_GESENDET'), this.translate.instant('RESETPASSWORD.HINWEIS')).then(() => {
        // window.location.reload();
      });
      return;
    })
    .catch((error) => {
      console.log('onSignin 2', error);
      this.loadingVisible = false;
      alert( this.translate.instant('RESETPASSWORD.FEHLER_AUFGETRETEN'), this.translate.instant('RESETPASSWORD.FEHLER')).then(() => {
        // window.location.reload();
      });
    });
  }
  public onRequest() {
    // this.router.navigate(['/passwordreset']);
    // console.log('onSignin ');
    this.loadingVisible = true;
    this.authService.benutzerrequest( { email: this.myForm2['email']})
    .then((res) => {
      // console.log('onSignin 1');
      this.loadingVisible = false;
      alert( this.translate.instant('RESETPASSWORD.EMAIL_BENUTZERNAME_LISTE'), this.translate.instant('RESETPASSWORD.HINWEIS')).then(() => {
        // window.location.reload();
      });
      return;
    })
    .catch((error) => {
      console.log('onSignin 2', error);
      this.loadingVisible = false;
      alert( error.statusText, this.translate.instant('RESETPASSWORD.FEHLER')).then(() => {
        // window.location.reload();
      });
    });
  }

  public onCancel() {
    if (this.authService.currentUser != null) {
      this.router.navigate(['/profil']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): any {
    // console.log(JSON.stringify(environment));
    if (AppConfig.settings.testversion) {
      this.sText = this.sTestText;
      this.sTitel = this.sTestTitel;
    } else {
      this.sText = this.sProduktivText;
      this.sTitel = this.sProduktivTitel;
    }
    this.myForm = this.fb.group({
      benutzername: [environment.benutzername, Validators.required],
    });
    this.myForm2 = this.fb.group({
      email: [null, Validators.required],
    });
    this.benutzername = this.myForm.controls.benutzername;
    this.email = this.myForm2.controls.email;

    // this.resetButtonOptions = {
    //   icon: 'arrowright',
    //   text: 'zurücksetzen',
    //   type: 'success',
    //   useSubmitBehavior: false,
    //   onClick: (e) => {
    //     this.okReset(e);
    //   }
    // };
    this.cancelButtonOptions = {
      icon: 'clear',
      text: this.translate.instant('RESETPASSWORD.ABBRECHEN'),
      type: 'button',
      useSubmitBehavior: false,
      onClick: (e) => {
        this.onCancel();
      }
    };

    this.benutzernameKontrolle = this.benutzernameKontrolle.bind(this);


    if(this.route.snapshot.queryParams.typ) {
      this.vergessentyp = parseInt(this.route.snapshot.queryParams.typ);
    }
  }

  public onEinwilligen() {
    this.authService.dsgvo = 'ok';
    this.authService.put('/rechte/dsgvo', null).then(
      () => {
      }
    ).catch((error) => {
      console.log('onSignin catch 2', error);
    });

    if (!AppConfig.settings.showinfo) {
      this.AppLoad();
    } else {
      this.infoProduktivVisible = true;
    }
  }

  public AppLoad() {
  }
  public registerNew() {
    this.router.navigate(['/register']);

  }


  public onInfoProduktivVerstanden() {
    this.AppLoad();
  }

  public onInfoProduktivAbbrechen() {
    this.authService.logout(false);
  }
  public onAbbrechen() {
    this.authService.logout(false);
  }

  public okReset(e){

    console.log('reset');
    // this.successMessage=true;

    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {
    //  this.onSignin();
    this.onReset();
    // this.successMessage=true;
    }

  }

  public okRequest(e) {
    
    console.log('reset');
    // this.successMessage=true;

    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {
    //  this.onSignin();
    this.onRequest();
    // this.successMessage=true;
    }
  }

  benutzernameKontrolle(e) {
    if (e) {
      for (const val of e.value) {
        if (this.benutzerPattern.indexOf(val) === -1) {
          return false;
        }
      }
      return true;
    }
  }
  
  wechsel() {
    switch(this.vergessentyp) {
      case enuVergessen.Benutzername:
        this.vergessentyp = enuVergessen.Passwort;
        break;
        case enuVergessen.Passwort:
        this.vergessentyp = enuVergessen.Benutzername;
        break;
    }
  }
}
