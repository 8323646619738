import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { alert } from 'devextreme/ui/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { AppConfig } from '../app.config';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import validationEngine from 'devextreme/ui/validation_engine';
import {  GlobaleDatenService } from '../services/globaledaten.serice';
import * as funktionen from './../globals/functions';
import { AppRouteChangeService } from '../services/app-route-change.service';
import { DxButtonComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariable } from '../globals/globals';
import { enuKunde } from '../app-config.model';
import { Datenschutz } from '../services/user.interface';
import * as Sentry from "@sentry/angular";
import { enuVergessen } from '../resetpassword/resetpassword.component';

export class Loginoauth2Upgrade {
  email: string;
  newsletter: boolean = true;
  datenschutz: boolean = false;

  constructor(

    ) {

    }
}

@Component({
  selector: 'app-loginoauth2-component',
  templateUrl: './loginoauth2.component.html',
  styleUrls: ['./loginoauth2.component.scss']
})
export class Loginoauth2Component implements OnInit {
  loginoauth2buttonOptions: any;
  loginoauth2fieldsOptions: { onFocusIn: () => void; };
  userfieldOptions: any;
  passwordfieldOptions: any;
  kurs_id: any;
  reg: any;
  infotext: string;
  infoheader: string;

  benutzerPattern: string = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ.-@_';
  // benutzerPattern:any= /^abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ\.\-@_/;

  anmeldenButtonOptions: any;

  upgradeVisible: boolean = false;
  newsletter: boolean = true;
  datenschutz: boolean = false;
  upgradeButtonOptions: any;
  cancelButtonOptions: any;

  buttonInfoDatenschutz: any;
  buttonInfoNewsletter: any;

  daten: Loginoauth2Upgrade;

  enuKunde = enuKunde;
  enuVergessen = enuVergessen;
  
  @ViewChild('valGroupDatenschutz', { static: false }) valGroupDatenschutz: DxValidationGroupComponent;
  @ViewChild('fortfahren', { static: false }) fortfahrenButton: DxButtonComponent;

  menuLarge: boolean;
  popupWidth: number;
  popupHeight: number;


  mitnewsletter = AppConfig.kunde_settings.newsletter;
  nurgdsgvo: any;
  textparams: {};
  fortfahrentext: any;

  constructor(
    private fb: UntypedFormBuilder,
    public authService: AuthService,
    // private deviceService: DeviceDetectorService,
    private router: Router,
    public route: ActivatedRoute,

    public gdService: GlobaleDatenService,
    private ngZone: NgZone,
    public appRouteChangeService: AppRouteChangeService,
    public translate: TranslateService
  ) {

    this.sProduktivText = `Sie melden sich am –  Produktivsystem – der Bergwacht Bayern an.
    Sämtliche Dateneingaben und –änderungen in diesem System
    führen somit zu einer Änderung der Produktivdaten.'`;
    this.sTestText = `Sie melden sich am –  Testsystem – der Bergwacht Bayern an.
    Sämtliche Dateneingaben und –änderungen in diesem System
    werden nur temporär gespeichert!.`;

    this.passwordMode = 'password';

    this.daten = new Loginoauth2Upgrade();

    this.userfieldOptions = {
      onKeyDown(e){
        // if(gdService.istKunde(enuKunde.AVS)) {
          return gdService.onKeyDown(e);
        // }
      },
      onFocusIn: () => {
        // this.captchaVisible = true;
      },

    };

    this.okLogin = this.okLogin.bind(this);

    this.okfortfahren = this.okfortfahren.bind(this);
    this.kontowechseln = this.kontowechseln.bind(this);

    this.onUpgrade = this.onUpgrade.bind(this);
    this.onUpgradeAbbrechen = this.onUpgradeAbbrechen.bind(this);
    // this.passwordOptions = this.onUpgradeAbbrechen.bind(this);

    this.passwordfieldOptions = this.passwordOptions();

    this.benutzernameKontrolle = this.benutzernameKontrolle.bind(this);

    this.getPaddingLeftInner = this.getPaddingLeftInner.bind(this);
    this.getPaddingLeftOuter = this.getPaddingLeftOuter.bind(this);
    this.getPaddingRightOuter = this.getPaddingRightOuter.bind(this);
    this.getMaxWidth = this.getMaxWidth.bind(this);

    this.textparams = { username: this.authService.currentUser};
  }

  passwordOptions() {
    return {
      mode: this.passwordMode,
      buttons: [{
          name: 'password',
          location: 'after',
          options: {
              type: 'default',
              height: '37px',
              elementAttr: {
                class: 'password',
              },
              icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
              onClick: () => {
                this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
                this.passwordfieldOptions = this.passwordOptions();
              }
          }
      }],
      onEnterKey: () => {
        this.onSignin();
      },
    }
}



  myForm: UntypedFormGroup;
  public benutzername: AbstractControl;
  public password: AbstractControl;

  public loadingVisible = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  access_token: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  refresh_token: string;
  public myAppConfig = AppConfig;

  public datenschutzVisible = false;
  public dsgvo = false;
  public deviceInfo: any;
  public iexplorer: boolean;

  public infoProduktivVisible = false;

  dsgvoItem: Datenschutz;
  passwordMode: string;

  public sTitel: string;
  public sProduktivTitel = 'Achtung Produktivbetrieb!';
  public sTestTitel = 'Achtung Testsystem!';

  public sText: string;
  public sProduktivText = 'Achtung Produktivbetrieb!';
  public sTestText = 'Achtung Testsystem!';


  public onSignin() {
    // console.log('onSignin ');
    // this.gdService.Reset();
    let localversion = null;
    if(localStorage.getItem('Version')) localversion = localStorage.getItem('Version');
    if ((localversion <= GlobalVariable['version'])) {
      localStorage.setItem('Version', GlobalVariable['version']);
      this.loadingVisible = true;
      try {

        this.authService.login( { benutzername: this.myForm['benutzername'].trim(), password: this.myForm['password'], ispasswordencoded: false}, true)
        .then((res) => {
          // console.log('onSignin 1');
          this.loadingVisible = false;
          if (!res['website'] || res['dsgvo']) {
            this.dsgvoItem = res['dsgvo'];
            this.daten.email = res['email'];
            this.nurgdsgvo = res['website'];
            this.upgradeVisible = true;

            this.popupWidth = 650;
            if(this.mitnewsletter && !this.nurgdsgvo) {
              this.popupHeight = 500;
            } else {
              this.popupHeight = 400;
            }
            if (this.popupWidth >=  window.innerWidth) {
              this.popupWidth = window.innerWidth - 25;
            }
            if (750 >=  window.innerHeight) {
              this.popupHeight = window.innerHeight - 50;
        
            }
        
          } else {
            this.gdService.anrede = res['anrede'];
            this.gdService.vorname = res['vorname'];
            this.gdService.firmenname = res['firmenname'];
            this.gdService.nachname = res['nachname'];
            this.gdService.mitglied = res['mitglied'];
            this.gdService.mitglied_id = res['mgd_id'];
            this.gdService.benutzer_id = res['benutzer_id'];
            localStorage.setItem('mgd_id', this.gdService.mitglied_id);
            if(this.gdService.vorname) {
              localStorage.setItem('mgd_vorname', this.gdService.vorname);
            } else if(this.gdService.firmenname) {
              localStorage.setItem('mgd_vorname', this.gdService.firmenname);
            } else {
              localStorage.setItem('mgd_vorname', '');
            }
            localStorage.setItem('benutzer_id', this.gdService.benutzer_id);
            this.gdService.newsletter = res['newsletter'];
            this.gdService.loadMitglied().then((data) => {
              this.gdService.setCookies();
            });
            this.onSendToken(res);
          }
        })
        .catch((error) => {
          // console.log('onSignin 2', error);
          this.loadingVisible = false;
          if (error instanceof HttpErrorResponse) {
    
            const e =  error.error;
            // console.log('Fehler', e);
            if (e && e.auth) {
              if (e.auth[0] === 'Too many attempts!') {
                alert( this.translate.instant('LOGIN.UNGUELTIGE_ZUGRIFFE_BENUTZER'),  this.translate.instant('LOGIN.FEHLER'));
              } else if (e.auth[0] === 'Bad username or password') {
                alert(this.translate.instant('LOGIN.EMAIL_ODER_KENNWORT_FALSCH'), this.translate.instant('LOGIN.FEHLER'));
              } else if (e.auth[0] === 'AppGesperrt') {
                // eslint-disable-next-line max-len
                alert(this.translate.instant('LOGIN.AVSOFFICE_WARTUNGSARBEITEN_NICHT_AKTIV'),  this.translate.instant('LOGIN.FEHLER'));
              } else {
                alert(this.translate.instant('LOGIN.EINGABEN_NICHT_KORREK')+'<br>'+this.translate.instant('LOGIN.EINGABEN_UEBERPRUEFE'), this.translate.instant('LOGIN.FEHLER'));
              }
            } else if (error.status === 400 && error.statusText === 'AppGesperrt') {
              alert(this.translate.instant('LOGIN.AVSOFFICE_WARTUNGSARBEITEN_NICHT_AKTIV'), this.translate.instant('LOGIN.HINWEIS'));
            } else if (error.status === 400 && error.statusText === 'Dieser Benutzer ist keiner Person zugeordnet.') {
              alert(error.statusText, this.translate.instant('LOGIN.HINWEIS'));
            } else {
              alert(this.translate.instant('LOGIN.EINGABEN_NICHT_KORREK')+'<br>' + this.translate.instant('LOGIN.EINGABEN_UEBERPRUEFE'), this.translate.instant('LOGIN.FEHLER'));
            }
          } else {
            // console.log('Fehler', error);
            Sentry.captureMessage("Login", {
              tags: {
                status: error.status,
                error: error.message,
                stack: error.stack,
              }
            });
            if (error.status === 400 && error.statusText === 'AppGesperrt') {
              alert(this.translate.instant('LOGIN.AVSOFFICE_WARTUNGSARBEITEN_NICHT_AKTIV'), this.translate.instant('LOGIN.HINWEIS'));
            } else {
              alert(this.translate.instant('LOGIN.FEHLER_ZUGRIFF_DATENBANK'), this.translate.instant('LOGIN.FEHLER'));
            }
          }
        }).finally(() => {
          this.loadingVisible = false;
    
        });
      } catch(error) {
        this.loadingVisible = false;
      }
    } else {
      alert( this.translate.instant('VERSIONKONFLIKT'), this.translate.instant('LOGIN.FEHLER')).then(() => {
        window.location.reload();
      });
    }
  }

  private onSendToken(res){
    console.log('onSendToken', parent);
    if (parent != null && !parent.closed) {
      // console.log('onSendToken postMessage 2', send, location.origin);
      // parent.postMessage(send,location.origin);
      // console.log('onSendToken postMessage 2', send, parent.location.origin);
      // parent.postMessage(send, parent.location.origin);
      // parent.postMessage(send);
      // window.opener.postMessage(send,'https://login.test.climboffice.com');
      // window.opener.postMessage(send, document.referrer);
      // console.log('res.oauth_token', res.oauth_token);
      window.opener.postMessage(res.oauth_token, document.referrer);
    }
    window.close();
  }


  onUpgrade(e) {
    // const groupobject = e.validationGroup.group;
    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {
      this.loadingVisible = true;

      if(this.nurgdsgvo) {
        this.authService.putUrl('/portal/benutzeracceptdsgvo'
          , { benutzername : this.myForm['benutzername']
          , locale :localStorage.getItem('locale')
          , vertragdokument_id: this.dsgvoItem.vertragdokument_id}).then( (data) => {
          this.upgradeVisible = false;
          this.loadingVisible = false;
          if (data === '') {
            this.onSignin();
          }
        }).catch((error) => {
          this.loadingVisible = false;
          alert(error.statusText, this.translate.instant('REGISTER.FEHLER'));
        });
      } else {
        this.authService.putUrl('/portal/addbenutzerwebsite_frombenutzeroffice'
          , { benutzername : this.myForm['benutzername']
          ,  password : this.myForm['password']
          , email : this.daten['email']
          , newsletter : this.daten['newsletter']
          , locale :localStorage.getItem('locale')
          , vertragdokument_id: this.dsgvoItem.vertragdokument_id}).then( (data) => {
          this.upgradeVisible = false;
          this.loadingVisible = false;
          if (data === '') {
            this.onSignin();
          }
        }).catch((error) => {
          this.loadingVisible = false;
          alert(error.statusText, this.translate.instant('REGISTER.FEHLER'));
        });
      }
    }

  }

  benutzernameKontrolle(e) {
    if (e) {
      for (const val of e.value) {
        if (this.benutzerPattern.indexOf(val) === -1) {
          return false;
        }
      }
      return true;
    }
  }

  async openDSGVO(item){
    console.log("item: ", item)
    let dsgvoVorhanden = await funktionen.openDSGVO('_blank', this.authService, item.typ, this.dsgvoItem, this.myForm['benutzername']);
    if(!dsgvoVorhanden){
      this.upgradeVisible = false;
      this.onSignin();
    }
  }


  onUpgradeAbbrechen(e) {
    this.upgradeVisible = false;
  }

  ngOnInit(): any {
    // console.log(JSON.stringify(environment));
    this.fortfahrentext = this.translate.instant('LOGIN.MITFORTFAHREN', this.textparams);

    this.myForm = this.fb.group({
        benutzername: [environment.benutzername, Validators.required],
        password: [environment.password, Validators.required],
    });
    this.benutzername = this.myForm.controls.benutzername;
    this.password = this.myForm.controls.password;


    if (this.authService.CheckExpDate()) {
      this.kontowechseln(null);
    } 
  }
  onCancel() {
    throw new Error('Method not implemented.');
  }

  datenschutzComparison() {
    return true;
  }

  onResetPassword(e) {
    window.open('/resetpassword', '_blank');
    window.close();
    return false;
  }


  public registerNew() {
    this.ngZone.run(() => this.router.navigate(['/register']));
  }


  public okLogin(e){
    // this.loadingVisible = true;
    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {
    this.onSignin();
    }
    // this.loadingVisible = false;

  }

  public okfortfahren(e) {

    this.authService.get('/useroauth2token').then((data) => {
      this.onSendToken(data);
      
    })
  }

  public kontowechseln(e) {
    localStorage.removeItem('mgd_id');
    localStorage.removeItem('mgd_vorname');
    localStorage.removeItem('benutzer_id');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expdate');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('kletterhallen_id');

    this.authService.deleteCookies();
    this.authService.stopRefreshTokenTimer();
    // this.authService.currentUser = null;
    this.gdService.anrede = null;
    this.gdService.vorname = null;
    this.gdService.firmenname = null;
    this.gdService.nachname =null;
    this.gdService.mitglied = null;
    this.gdService.mitglied_id = null;
    this.gdService.benutzer_id = null;


    this.gdService.newsletter = null;
  }

  handleToken(e) {
   console.log('Token:', e);
  }

  reloadData(){
    this.fortfahrentext = this.translate.instant('LOGIN.MITFORTFAHREN', this.textparams);
    setTimeout(() => {
      this.fortfahrenButton.instance.repaint();
    }, 0);
  }
  getPaddingLeftOuter() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        if(this.gdService.isMobileDevice) {
          return '20px';
        } else {
          return '0px';
        }
      case enuKunde.KFS: 
        return '50px';
      case enuKunde.Standard: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '250px';
        }
      case enuKunde.Template: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '50px';
        }
    }

  }

  getPaddingRightOuter() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '0px';
      case enuKunde.KFS: 
        return '0px';
      case enuKunde.Standard: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '250px';
        }
      case enuKunde.Template: 
        if(this.gdService.isMobileDevice) {
          return '0px';
        } else {
          return '50px';
        }
    }
  }

  getPaddingLeftInner() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '0px';
      case enuKunde.KFS: 
      case enuKunde.Standard: 
      case enuKunde.Template: 
      if(this.gdService.isMobileDevice) {
        return '20px';
      } else {
        return '50px';
      }
    }

  }

  getMaxWidth() {
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
        return '450px';
      case enuKunde.KFS: 
      case enuKunde.Standard: 
      case enuKunde.Template: 
        return '390px';
    }

  }

  getDisplay(){
    switch(AppConfig.settings.kunde) {
      case enuKunde.AVS: 
      case enuKunde.KFS: 
        return 'inherit';
      case enuKunde.Standard: 
      case enuKunde.Template: 
        return 'inline-flex';
    }
  }

}
