import { AppConfig } from './../app.config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User, UserPwdReset, UserPwdReset2, UserRequest } from './user.interface';
import { alert } from 'devextreme/ui/dialog';
import * as funktionen from '../globals/functions';
import moment from 'moment';
import { GlobalVariable } from '../globals/globals';
import { TranslateService } from '@ngx-translate/core';

declare var firebase: any;

@Injectable()
export class AuthService  {
  // state = new Subject<boolean>();

  // public password: string;
  private url: string;
  private permissions: Array<boolean>;
  public dsgvo: string;
  private inRefreshmode: boolean;
  versionskonfliktVisible: any;

  local = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    public translate: TranslateService,
  ) {
    this.inRefreshmode = false;
    // eslint-disable-next-line new-parens
    this.permissions = new Array;
    this.url = AppConfig.settings.restService;
    // console.log('restService' + environment.restService);
    if (localStorage.getItem('permissions')) {
      try {
        this.permissions =  JSON.parse( localStorage.getItem('permissions'));
      } catch (error) {
        console.log('permissions', error, localStorage.getItem('permissions'));
      }
    }


    if (localStorage.getItem('dsgvo')) {
      this.dsgvo = localStorage.getItem('dsgvo');
    }
    if (localStorage.getItem('local')) {
      this.local = localStorage.getItem('local');
    }
    // console.log('constructor ' + this.access_token);
  }

  get currentUser() {
    if (localStorage.getItem('currentUser')) {
      return localStorage.getItem('currentUser');
    }
    return '';
  }

  set currentUser(currentUser) {
    localStorage.setItem('currentUser', currentUser);
  }

  get access_token() {
    if (localStorage.getItem('access_token')) {
      return localStorage.getItem('access_token');
    }
    return '';
  }

  set access_token(access_token) {
    localStorage.setItem('access_token', access_token);
  }

  get refresh_token() {
    if (localStorage.getItem('refresh_token')) {
      return localStorage.getItem('refresh_token');
    }
    return '';
  }

  set refresh_token(refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
  }

  get expdate() {
    if (localStorage.getItem('expdate')) {
      try {
        return new Date(JSON.parse( localStorage.getItem('expdate')));
      } catch (error) {
        console.log('expdate', error, localStorage.getItem('expdate'));
      }
    }
    return '';
  }
  
  set expdate(expdate) {
    localStorage.setItem('expdate', JSON.stringify(expdate));
  }

  get expires_in() {
    if (localStorage.getItem('expires_in')) {
      try {
        return JSON.parse( localStorage.getItem('expires_in'));
      } catch (error) {
        console.log('expires_in', error, localStorage.getItem('expires_in'));
      }
    }
    return '';
  }

  set expires_in(expires_in) {
    localStorage.setItem('expires_in', JSON.stringify(expires_in));
  }

  hasPermission(permission: string) {
    if (this.permissions[permission]) {
      return this.permissions[permission];
    }
    return false;
  }

  getLocal() {
    if(localStorage.getItem('local')) {
      return localStorage.getItem('local');
    } else if(this.local) {
      return this.local;
    } else {
      return 'de';
    }
  }

  register( data: any): Promise<{}> {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.url + '/register', JSON.stringify({data : data.send}),
      {headers: {'Content-Type': 'application/json', locale: this.getLocal()}})
      .subscribe(
      res => {
        resolve(res);
      },
      error => {
        // console.log('error', error);
        reject(error);
      });

    });
  }

  register2( id): Promise<{}> {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.url + '/register2', JSON.stringify({id}),
      {headers: {'Content-Type': 'application/json', locale: this.getLocal()}})
      .subscribe(
      res => {
        resolve(true);
      },
      error => {
        // console.log('error', error);
        reject(error);
      });

    });
  }


  loggedIn(): boolean {
    console.log('loggedIn ' + this.access_token + ' ' + this.access_token.length);
    if (this.CheckExpDate()) {
      this.logout(false, false);
      return false;
    } else {
      this.CheckLocalVersion();
    }
    // if (funktionen.isEmptyString(this.dsgvo)) {
    //   return false;
    // }
    return this.access_token.length > 0;
  }

  login( user: User, oauth2 = false): Promise<{}> {
    // this.gdService.mitglied = null;
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.url + '/login', JSON.stringify({username: user.benutzername, password: user.password, ispasswordencoded: user.ispasswordencoded, oauth2: oauth2}),
      {headers: {'Content-Type': 'application/json', 'locale': this.getLocal()}})
      .subscribe(
      res => {
        // this.gdService.user = user.email;
        // this.gdService.password = user.password;
        // this.getUrl('/portal/benutzerwebsite').then((data)  => {
        //   this.gdService.mitglied = data as any[];
        // });
        // console.log('res', res);
        if (res['website'] && !res['dsgvo']) {
          // Kletterhallen logged aus, wenn expdate später gesetzt wird
          this.expires_in =  res['expires_in'];
          this.expdate = new Date((new Date()).getTime() + this.expires_in * 2 * 1000);
          this.access_token = res['access_token'];
          this.refresh_token = res['refresh_token'];
          this.permissions =  res['permissions'];
  
          this.currentUser = user.benutzername;
          // this.password = user.password;
          localStorage.setItem('permissions', JSON.stringify( this.permissions));
          // localStorage.setItem('dsgvo', this.dsgvo);
  
          if(!oauth2) {
            this.startRefreshTokenTimer();
          }
        }
        resolve(res);
      },
      error => {
        // console.log('error', error);
        reject(error);
      });

    });
  }

  benutzerrequest( user: UserRequest): Promise<{}> {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      // eslint-disable-next-line max-len
      this.http.post(this.url + '/benutzerrequest', JSON.stringify({email: user.email}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
      .subscribe(
      res => {
        // console.log('res', res);
        resolve(true);
      },
      error => {
        // console.log('error', error);
        reject(error);
      });

    });
  }

  passwordreset( user: UserPwdReset): Promise<{}> {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      // eslint-disable-next-line max-len
      this.http.post(this.url + '/pwdreset', JSON.stringify({benutzername: user.benutzername}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
      .subscribe(
      res => {
        // console.log('res', res);
        resolve(true);
      },
      error => {
        // console.log('error', error);
        reject(error);
      });

    });
  }

  passwordreset2( user: UserPwdReset2): Promise<{}> {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      // eslint-disable-next-line max-len
      this.http.post(this.url + '/pwdreset2', JSON.stringify({id: user.id, password: user.password}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
      .subscribe(
      res => {
        // console.log('res', res);
        resolve(true);
      },
      error => {
        // console.log('error', error);
        reject(error);
      });

    });
  }

  passwordresetbenutzer( id: string): Promise<{}> {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      // eslint-disable-next-line max-len
      this.http.post(this.url + '/api/pwdresetbenutzer', JSON.stringify({id}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
      .subscribe(
      res => {
        // console.log('res', res);
        resolve(res);
      },
      error => {
        // console.log('error', error);
        reject(error);
      });

    });
  }

  Access_token(): string {
    return 'Bearer ' + this.access_token;
  }
  CheckExpDate(): boolean {
    if (funktionen.isEmptyString(this.access_token)) {
      console.log('CheckExpDate access_token empty')
      return true;
    }
    const d = new Date();
    if (funktionen.isEmptyDate(this.expdate) || d > this.expdate) {
      console.log('CheckExpDate Date')
      return true;
    }
    return false;
  }

  
  CheckLocalVersion(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let localversion = null;
      if (localStorage.getItem('Version')) localversion = localStorage.getItem('Version');
      if (localversion !== GlobalVariable['version']) {
        if(GlobalVariable['version'] > localversion) {
          localStorage.setItem('Version', GlobalVariable['version']);
        } else {
          if (!this.versionskonfliktVisible) {
            this.versionskonfliktVisible = true;
            alert( this.translate.instant('VERSIONKONFLIKT'), this.translate.instant('LOGIN.FEHLER')).then(() => {
              this.versionskonfliktVisible = false;
              window.location.reload();
              resolve();
            });
          }
        }

      } else {
        resolve();
      }
    });
  }


  getBlob(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      if (this.CheckExpDate()) {
        this.logout();
        reject('Loginfehler');
        return;
      } else {
        this.CheckLocalVersion();
      }
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.access_token);
      this.http.get(this.url + url, {headers: {Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}, responseType: 'blob'}
      ).subscribe(
        (res) => {
          // console.log('getBlob ok');
          const b = new Blob([res]);
          resolve(b);
        },
        (error) => {
          // console.log('getBlob error');
          console.log(error.status);
          if (error.status && error.status === 401) {
            this.refresh()
            .then((res) => {
              // console.log('refresh ' + JSON.stringify(res));

              headers = new Headers();
              headers.append('Content-Type', 'application/json');
              headers.append('Authorization', 'Bearer ' + this.access_token);

              this.http.get(this.url + url, {headers: {Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}, responseType: 'blob'})
              .subscribe(
                (res1) => {
                  resolve(res1);
                },
                (error1) => {
                  reject(error1);
                }
              );
            })
            .catch((err) => {
              this.logout();
              console.log(error);
            });
          } else {
            reject(error);
            return;
          }
        }
      );
    });
  }

  get(url: string, no_access_token = false): Promise<{}> {
    return new Promise((resolve, reject) => {
      if (!no_access_token && this.CheckExpDate()) {
        this.logout();
        reject('Loginfehler');
        return;
      } else {
        this.CheckLocalVersion();
      }
      if (!no_access_token && this.access_token === '') {
        reject(false);
      }
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.access_token);

      // console.log('get', this.url + url);
      this.http.get(this.url + url, {headers: {'Content-Type': 'application/json', 'locale': this.getLocal(), Authorization: 'Bearer ' + this.access_token}})
      .subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          console.log(error.status);
          if (error.status && error.status === 401) {
            this.refresh()
            .then((res) => {
              // console.log('refresh ' + JSON.stringify(res));

              headers = new Headers();
              headers.append('Content-Type', 'application/json');
              headers.append('Authorization', 'Bearer ' + this.access_token);

              this.http.get(this.url + url, {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
              .subscribe(
                (res1) => {
                  resolve(res1);
                },
                (error1) => {
                  reject(error1);
                }
              );
            })
            .catch((err) => {
              this.logout();
              console.log(error);
            });
          } else {
            reject(error);
            return;
          }
        }
      );
    });
  }

  // put(url: string, data: any): Promise<{}> {
  //   return new Promise((resolve, reject) => {
  //     if (this.CheckExpDate()) {
  //       this.logout();
  //       reject('Loginfehler');
  //       return;
  //     } else {
  //       this.CheckLocalVersion();
  //     }
  //     if (this.access_token === '') {
  //       reject(false);
  //       return;
  //     }
  //     let headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     headers.append('Authorization', 'Bearer ' + this.access_token);

  //     // console.log('put ' + this.url + url + ' ' + JSON.stringify(data), data);

  //     // eslint-disable-next-line max-len
  //     this.http.put(this.url + url, JSON.stringify({data}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
  //     .subscribe(
  //       (res) => {
  //         // console.log(JSON.stringify(res));
  //         resolve(res);
  //       },
  //       (error) => {
  //         console.log(JSON.stringify(error));
  //         console.log(error.status);
  //         if (error.status && error.status === 401) {
  //           this.refresh()
  //           .then((res) => {
  //             console.log('refresh ' + JSON.stringify(res));

  //             headers = new Headers();
  //             headers.append('Content-Type', 'application/json');
  //             headers.append('Authorization', 'Bearer ' + this.access_token);

  //             // eslint-disable-next-line max-len
  //             this.http.put(this.url + url, JSON.stringify({data}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
  //             .subscribe(
  //               (res1) => {
  //                 console.log(JSON.stringify(res1));
  //                 resolve(res1);
  //               },
  //               (error1) => {
  //                 console.log(JSON.stringify(error1));
  //                 reject(error1);
  //               }
  //             );
  //           })
  //           .catch((err) => {
  //             this.logout();
  //             console.log(error);
  //           });
  //         } else {
  //           reject(error);
  //           return;
  //         }
  //       }
  //     );
  //   });
  // }

  async put(url: string, data: any, no_access_token = false): Promise<{}> {
    return new Promise((resolve, reject) => {
      if (!no_access_token && this.CheckExpDate()) {
        this.logout();
        reject('Loginfehler');
        return;
      } else {
        this.CheckLocalVersion();
      }
      if (!no_access_token && this.access_token === '') {
        reject(false);
        return;
      }
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.access_token);

      // console.log('put ' + this.url + url + ' ' + JSON.stringify(data), data);

      // eslint-disable-next-line max-len
      this.http.put(this.url + url, JSON.stringify({data}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
      .subscribe(
        (res) => {
          // console.log(JSON.stringify(res));
          resolve(res);
        },
        (error) => {
          console.log(JSON.stringify(error));
          console.log(error.status);
          if (error.status && error.status === 401) {
            this.refresh()
            .then((res) => {
              console.log('refresh ' + JSON.stringify(res));

              headers = new Headers();
              headers.append('Content-Type', 'application/json');
              headers.append('Authorization', 'Bearer ' + this.access_token);

              // eslint-disable-next-line max-len
              this.http.put(this.url + url, JSON.stringify({data}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
              .subscribe(
                (res1) => {
                  console.log(JSON.stringify(res1));
                  resolve(res1);
                },
                (error1) => {
                  console.log(JSON.stringify(error1));
                  reject(error1);
                }
              );
            })
            .catch((err) => {
              this.logout();
              console.log(error);
            });
          } else {
            reject(error);
            return;
          }
        }
      );
    });
  }
  
  delete(url: string): Promise<{}> {
    return new Promise((resolve, reject) => {
      if (this.CheckExpDate()) {
        this.logout();
        reject('Loginfehler');
        return;
      } else {
        this.CheckLocalVersion();
      }
      if (this.access_token === '') {
        reject(false);
      }
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.access_token);

      // console.log('put ' + this.url + url + ' ' + JSON.stringify(data));

      this.http.delete(this.url + url, {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
      .subscribe(
        (res) => {
          console.log(JSON.stringify(res));
          resolve(res);
        },
        (error) => {
          console.log(JSON.stringify(error));
          reject(error);
        }
      );
    });
  }

  delay(milliseconds: number) {
    return new Promise<void>(resolve => {
        setTimeout(resolve, milliseconds);
    });
  }

  async refresh(): Promise < {} > {
    if (this.inRefreshmode) {
      while (this.inRefreshmode) {
        await this.delay(500);
        console.log('.');
      }
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }

    this.inRefreshmode = true;
    return new Promise((resolve, reject) => {
      if (this.access_token === '') {
        reject(false);
        this.inRefreshmode = false;
      }

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.access_token);

      // eslint-disable-next-line max-len
      this.http.post(this.url + '/auth/refresh', JSON.stringify({refresh_token: this.refresh_token}), {headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.access_token, 'locale': this.getLocal()}})
      .subscribe(
        (res) => {
          // console.log(JSON.stringify(res));
          this.access_token = res['access_token'];
          this.refresh_token = res['refresh_token'];
          this.expires_in =  res['expires_in'];
          this.expdate = new Date((new Date()).getTime() + this.expires_in * 2 * 1000);
          // // console.log('this.expdate', this.expdate);

          this.startRefreshTokenTimer();

          resolve(true);
          this.inRefreshmode = false;
        },
        (error) => {
          reject(error);
          this.inRefreshmode = false;
        }
      );
    });
  }

  logout(showSitzungAbgelaufen: boolean = false, loadStandard: boolean = true) {
    this.currentUser = null;
    // this.password = null;
    localStorage.removeItem('mgd_id');
    localStorage.removeItem('mgd_vorname');
    localStorage.removeItem('benutzer_id');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expdate');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('kletterhallen_id');

    this.deleteCookies();
    this.stopRefreshTokenTimer();

    // console.log('localStorage: ' + JSON.stringify(localStorage));
    console.log('logout');

    if (showSitzungAbgelaufen) {
      alert( 'Sitzung ist abgelaufen!', 'Hinweis').then(() => {
        // eslint-disable-next-line max-len
        if (loadStandard) window.location.replace(AppConfig.settings.myBase + '/index.html');  // index.html wird geladen, brauchts damit app neu geladen wird.
      });
    } else {
      // window.location.replace(environment.myBase + '/index.html');  // index.html wird geladen, brauchts damit app neu geladen wird.
      if (loadStandard) window.location.replace(AppConfig.settings.myBase + '/login');
    }
  }

  /*isAuthenticated() {
    console.log('isAuthenticated ' + this.loggedIn);
    return this.loggedIn;
  }*/

  getUrl(url: string): Promise<{}> {
    return new Promise((resolve, reject) => {

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      console.log('get', this.url + url);
      console.log('headers', {headers: {'Content-Type': 'application/json', 'locale': this.getLocal()}});
      this.http.get(this.url + url, {headers: {'Content-Type': 'application/json', 'locale': this.getLocal()}})
      .subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          console.log(error.status);
          reject(error);
          return;
        }
      );
    });
  }

  putUrl(url: string, data: any): Promise<{}> {
    return new Promise((resolve, reject) => {
      // console.log('put ' + this.url + url + ' ' + JSON.stringify(data), data);

      // eslint-disable-next-line max-len
      this.http.put(this.url + url, JSON.stringify({data}), {headers: {'Content-Type': 'application/json', 'locale': this.getLocal()}})
      .subscribe(
        (res) => {
          // console.log(JSON.stringify(res));
          resolve(res);
        },
        (error) => {
          console.log(JSON.stringify(error));
          console.log(error.status);
          reject(error);
          return;
        }
      );
    });
  }

  deleteUrl(url: string): Promise<{}> {
    return new Promise((resolve, reject) => {

      // console.log('put ' + this.url + url + ' ' + JSON.stringify(data));

      this.http.delete(this.url + url, {headers: {'Content-Type': 'application/json', 'locale': this.getLocal()}})
      .subscribe(
        (res) => {
          console.log(JSON.stringify(res));
          resolve(res);
        },
        (error) => {
          console.log(JSON.stringify(error));
          reject(error);
        }
      );
    });
  }
  getBlobUrl(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {

      this.http.get(this.url + url, { responseType: 'blob'})
      .subscribe(
        (res) => {
          // console.log('getBlob ok');
          const b = new Blob([res]);
          resolve(b);
        },
        (error) => {
          // console.log('getBlob error');
          console.log(error.status);
          reject(error);
        }
      );
    });
  }

  getFile(url: string): Promise<string> {
    return new Promise((resolve, reject) => {

      this.http.get(url, { responseType: 'text'})
      .subscribe(
        (res) => {

          resolve(res);
        },
        (error) => {
          // console.log('getBlob error');
          console.log(error.status);
          reject(error);
        }
      );
    });
  }

  deleteCookies() {
    const domain = AppConfig.settings.cookiedomain; //  ''; // inkl. Subdomains
    this.eraseCookie('expiredate', domain);
    this.eraseCookie('loggedin', domain);
    this.eraseCookie('nachname', domain);
    this.eraseCookie('vorname', domain);
    this.eraseCookie('anrede', domain);
  }

  eraseCookie(name, domain) {
    let coo = name + '=; Max-Age=-99999999;';
    if (domain) coo += ';domain=' + domain;

    document.cookie = coo;
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // console.log('startRefreshTokenTimer');
    if (funktionen.isEmptyString(this.access_token)) return;
    
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.access_token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    // const timeout = 10 * 1000;
    if (timeout>0) {
      this.refreshTokenTimeout = setTimeout(() => {
        // console.log('refreshTokenTimeout');
        this.refresh();
      }, timeout);
    } else {
      this.refresh();
    }
  }

  public stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  getOrigin() {
    if(AppConfig.settings.originUrl) {
      return AppConfig.settings.originUrl;
    } else {
      return window.location.origin;
    }
  }
}
